<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden;min-height: calc(100vh - 100px); " width="200px">
				<div style="padding: 20px 20px;">
					<div>
						<a-button type="primary" style="width: 160px;" icon="plus" @click="addAct()">添加应用</a-button>
					</div>
					<div class="mt10">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<i class="iconfont ft14" :class="item.icon"></i>
							<span class="ft14 ml10">{{item.name}}</span>
						</div>

					</div>
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 0px 20px; background: #F7F8FA; min-height: calc(100vh - 100px);">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menus: [
					{
						name: '上架中',
						icon: 'iconshangjia',
						key: 'application_index',
						link: '/application'
					},
					{
						name: '开发中',
						icon: 'iconsubnav_application02',
						key: 'application_developing',
						link: '/application/developing'
					},
					{
						name: '激活码',
						icon: 'iconsubnav_application02',
						key: 'application_activation_code',
						link: '/application/activationCode'
					},
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
			addAct(){
				this.$router.push('/application/edit');
			},
		}
	}
</script>

<style>
	.merchant-layout-audit-num{
		background: #FF6600;
		border-radius: 8px;
		padding: 0px 5px 0px 5px;
		
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>
